/*@import "./palmetto-direflow-react-table.css";*/
/* All mixins should be prefixed with --pcl to avoid name conflicts when this package is imported */

*,
*:before,
*:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c6c5c5;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

html {
  --pcl-primary-background-color: #2961fd;
  --pcl-secondary-background-color: #e9ebf2;
  --pcl-primary-icon-color: white;
  --pcl-secondary-icon-color: rgba(0, 0, 0, 0.8);

  /* Text colors */

  --pcl-primary-text-color: #000000;

  /* Button colors */

  /* Primary Button */
  --pcl-primary-button-text-color: white;
  --pcl-primary-button-color: #2961fd;
  --pcl-primary-button-hover-color: #0a4bfc;
  --pcl-primary-button-active-color: #2252d6;
  --pcl-primary-button-deactivated-color: rgba(44, 91, 255, 0.5);

  /* Danger Button */
  --pcl-danger-button-text-color: white;
  --pcl-danger-button-color: #d0021b;
  --pcl-danger-button-hover-color: #eb021d;
  --pcl-danger-button-active-color: #b80217;
  --pcl-danger-button-deactivated-color: rgba(209, 2, 26, 0.5);

  /* Secondary Button */
  --pcl-secondary-button-text-color: #2961fd;
  --pcl-secondary-button-color: #ffffff;
  --pcl-secondary-button-hover-color: #f7f9ff;
  --pcl-secondary-button-active-color: #e8eeff;
  --pcl-secondary-button-deactivated-color: #ffffff;

  --pcl-green-button-text-color: #ffffff;
  --pcl-green-button-color: #238f55;
  --pcl-green-button-hover-color: #238f55;
  --pcl-green-button-active-color: #238f55;
  --pcl-green-button-deactivated-color: #238f55;

  /* TextFields */
  /* Material design specifies 54% for secondary text.
       We are going 60%. You change this, you get to
       explain it to Melissa. */
  --pcl-default-label-color: rgba(0, 0, 0, 0.6);
  --pcl-placeholder-text-color: rgba(0, 0, 0, 0.87);
  --pcl-required-textfield-color: #d0021b;
  --pcl-required-dropdown-color: #d0021b;

  /* Checkboxes */
  --pcl-primary-checkbox-color: #2961fd;

  /* Sections */

  --pcl-primary-section-title-color: #2961fd;

  /* cards */

  --pcl-summary-card-title-color: white;

  /* Status */
  --pcl-status-green-1: #417505;
  --pcl-status-green-2: #6dbe10;
  --pcl-status-green-3: #19c922;

  --pcl-status-grey-1: #a1a1a1;
  --pcl-status-grey-2: #4a4a4a;

  --pcl-status-yellow-2: #c2b61b;

  /* Error box */
  --pcl-error-container-border-color: #d0021b;
  --pcl-error-container-background-color: #f2dede;
  --pcl-error-container-text-color: #d0021b;

  /* Extensions */

  --pcl-extension-pending-color: #c2b61b;
  --pcl-extension-approved-color: #417504;
  --pcl-extension-denied-color: #d0021b;

  font-family: 'Roboto', 'Arial', sans-serif !important;
}

/* Headers */

.header-container {
  height: 56px;
  background-color: var(--pcl-primary-background-color);
  width: 100%;
  display: flex;
  flex-direction: row;
  color: var(--pcl-primary-icon-color);
}

/* Utilities */

.icon {
  color: var(--pcl-primary-icon-color);
  width: 24px;
  height: 24px;
}

.icon-2 {
  color: var(--pcl-secondary-icon-color);
  width: 24px;
  height: 24px;
}

.primary-icon {
  color: var(--pcl-primary-background-color);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.spacer-20 {
  width: 20px;
}

.spacer-10 {
  width: 10px;
}

.opacity-54 {
  opacity: 0.54;
}

.opacity-87 {
  opacity: 0.87;
}

.no-opacity-important {
  opacity: 1 !important;
}

.no-opacity-important-childs {
  opacity: 1 !important;
}

.title-54 {
  font-size: 12px;
  opacity: 0.54;
}

.title-54-18 {
  font-size: 18px;
  opacity: 0.54;
}

.title-54-16 {
  font-size: 16px;
  opacity: 0.54;
}

.title-54-14 {
  font-size: 14px;
  opacity: 0.54;
}

.title-87-24 {
  font-size: 24px;
  opacity: 0.87;
}

.title-87-16 {
  font-size: 16px;
  opacity: 0.87;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px !important;
  padding-left: 8px;
  height: 48px;
}

.header-title-container {
  font-size: 18px;
  margin-left: 8px;
}

.title-big {
  font-size: 16px;
  line-height: 1.2;
  text-align: left;
}

.title-small {
  font-size: 14px !important;
  line-height: 1.2;
  text-align: left;
  color: rgba(255, 255, 255, 0.87);
}

.full-height {
  height: 100%;
}

.height-10 {
  height: 10px;
}

.height-16 {
  height: 16px;
}

.height-20 {
  height: 20px;
}

.height-32 {
  height: 32px;
}

.form-shell {
  padding: 10px;
}

.vertical-center {
  align-items: center;
  justify-content: center;
}

.only-vertical-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.line-height-15 {
  line-height: 1.5;
}

.container-overflow-y {
  overflow-y: auto;
}

.header-container-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.truncate {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Errors */

.error-msg-container {
  border-radius: 2px;
  border: solid 1px var(--pcl-error-container-border-color);
  background-color: var(--pcl-error-container-background-color);
  color: var(--pcl-error-container-text-color);
  line-height: 1.31;
  font-size: 16px;
}

.error-container-margin {
  margin-left: 10px;
}

.error-container-padding {
  padding: 10px;
}

/* Address Module */

.address-module {
  width: 100%;
}

/* Custom */

.required::after {
  content: '●';
  width: 6px;
  height: 6px;
  margin-left: 2px;
  color: var(--pcl-required-textfield-color) !important;
}

.section-title {
  opacity: 0.87;
  font-size: 14px;
  height: 34px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  color: #2961fd;
}

/* Makes the calendar show up for the input */
/* See https://stackoverflow.com/questions/15530850/method-to-show-native-datepicker-in-chrome/45461709#45461709 */

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin: 0;
  color: transparent;
  background: transparent;
  outline: none;
}

/* adjust increase/decrease button */
input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type='date']::-webkit-clear-button {
  z-index: 1;
}

/* adjust increase/decrease button */
input[type='time']::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type='time']::-webkit-clear-button {
  z-index: 1;
}

/* */

/* Grid styling */

.body-cell {
  height: 43px;
  padding-left: 18px !important;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* color: var(--primary-text-color)  !important; */
  cursor: pointer !important;
  border-bottom: 1px solid #e0e0e0;
}

.body-header {
  padding-left: 18px;
  font-size: 16px !important;
  background: #f5f5f5 !important;
  font-weight: 500 !important;
  min-height: 42px !important;
  line-height: 0;
  box-sizing: border-box;
  box-shadow: 0 -1px 0 #e0e0e0 inset !important;
}

.form-margin {
  margin: 0 6px;
}

.assignment-card {
  width: 240px;
  margin: 6px;
  height: 84px;
}

.border {
  border: 1px solid #e0e0e0;
}

.cursor-pointer {
  cursor: pointer;
}

.display-none {
  display: none;
}

.padding-12 {
  padding: 12px;
}

.dropdown-margin-fix {
  margin-top: 0 !important;
}

a {
  text-decoration: none;
}

input[type='file'] {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}

.MuiInputBase-input {
  /* height: 2em !important; */
  font-size: 16px;
}

.MuiDataGrid-columnHeader {
  box-shadow: unset !important;
}
